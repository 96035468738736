import axios from 'axios';
import classnames from 'classnames';
import getSBABaseUrl from 'lib/getSBABaseUrl';
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import OvalLoader from './oval.svg';
import styles from './styles.scss';

const HowItWorks = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collapsedText, setCollapsedText] = useState(null);

  const load = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${getSBABaseUrl()}/api/content/intro`);
      setCollapsedText(data.body);
    } catch (e) {
      setCollapsedText(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={styles.container}>
      <button
        onClick={async (): Promise<void> => {
          if (collapsedText) {
            setOpen(!open);
          } else {
            await load();
            setOpen(true);
          }
        }}
        className={styles.button}
      >
        <span>Hvordan fungerer tjenesten</span>
      </button>
      <OvalLoader className={classnames(styles.loader, loading && styles.loading)} />
      <Collapse
        isOpened={open}
        theme={{
          collapse: styles.collapseWrapper,
          content: styles.collapseContent,
        }}
      >
        <div className={styles.article} dangerouslySetInnerHTML={{ __html: collapsedText }} />
      </Collapse>
    </section>
  );
};

export default HowItWorks;
