import axios from 'axios';
import getSBABaseUrl from 'lib/getSBABaseUrl';
import { ExamplesActionTypes, ExamplesActionCreators } from './types';

export const examplesRequest = (): ExamplesActionCreators => {
  return { type: ExamplesActionTypes.REQUEST };
};

export const examplesSuccess = (apiResults): ExamplesActionCreators => {
  const data = apiResults.map(({ id, matrikkelId: { adresse, postnr, poststed }, eiendom: { bilder = null } }) => ({
    id,
    adresse,
    postnr,
    poststed,
    bilde: bilder.length > 0 ? bilder[0].url : null,
  }));
  return { type: ExamplesActionTypes.SUCCESS, data };
};

export const examplesFailure = (error): ExamplesActionCreators => {
  return { type: ExamplesActionTypes.FAILURE, error };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const doFetchExamples = () => {
  return async (dispatch): Promise<void> => {
    dispatch(examplesRequest());
    try {
      const { data } = await axios.get(`${getSBABaseUrl()}/api/examples`);
      dispatch(examplesSuccess(data));
    } catch (err) {
      dispatch(examplesFailure(err));
    }
  };
};
