import getConfig from 'next/config';

const getSBABaseUrl = (): string => {
  const {
    publicRuntimeConfig: { sbaUrl },
  } = getConfig();
  return sbaUrl;
};

export default getSBABaseUrl;
