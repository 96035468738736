import Link from 'next/dist/client/link';
import React from 'react';
import styles from './styles.scss';

export interface Props {
  id: string;
  snr: number;
  anr: number;
  postnr: string;
  poststed: string;
  adresse: string;
}

const SearchResult = (props: Props): JSX.Element => {
  const { id, adresse, postnr, poststed, anr, snr } = props;
  return (
    <Link href={`/overview?tilstandsrapportId=${id}`} as={`/${id}`}>
      <a className={styles.item}>
        <span className={styles.address}>
          {adresse}
          {postnr && `, ${postnr}`}
          {poststed && ` ${poststed}`}
        </span>
        <span className={styles.matrikkel}>
          {anr && `Andelsnr: ${anr}`}
          {snr && `Seksjonsnr: ${snr}`}
        </span>
      </a>
    </Link>
  );
};

export default SearchResult;
