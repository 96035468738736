import Home from 'components/containers/Home';
import LoginLogoutLayout from 'layouts/LoginLogout';
import Head from 'next/head';
import React from 'react';

const PageHome: React.FunctionComponent = () => (
  <LoginLogoutLayout>
    <Head>
      <title>Vendu: Smart Boliganalyse</title>
    </Head>
    <Home />
  </LoginLogoutLayout>
);

export default PageHome;
