/* eslint-disable import/prefer-default-export */

// Common utils for formatting various parts of the store.
// Used in both actions and selectors.

export const formatPostnr = (postnr): string => {
  return postnr.toString().padStart(4, '0');
};

export const toTitleCase = (text): string => {
  return text.replace(/\w\S*/g, word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
};
