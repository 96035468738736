import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { CityscapeSVG } from './Cityscape';
import Examples from './Examples';
import HeaderTitle from './HeaderTitle';
import HowItWorks from './HowItWorks';
import Search from './Search';
import styles from './styles.scss';

const Home = (): JSX.Element => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  useEffect(() => {
    const checkIfUserIsAuthenticated = async (): Promise<void> => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLoggedIn(true);
      } catch (err) {
        setIsLoggedIn(false);
      }
    };
    checkIfUserIsAuthenticated();
  }, []);

  return (
    <>
      <section className={styles.topContainer}>
        <HeaderTitle
          className={styles.headerTitle}
          headerText="Skal du kjøpe bolig?"
          subHeaderText="Smart Boliganalyse for en tryggere bolighandel."
        />
        {isLoggedIn && (
          <Search
            containerClassName={styles.search}
            resultContainerClassName={styles.searchResults}
            notificationsClassName={styles.searchNotifications}
          />
        )}

        <div className={styles.illustrationWrapper}>
          <section className={styles.illustrationContainer}>
            <CityscapeSVG className={styles.illustration} />
          </section>
        </div>

        <HowItWorks />
      </section>

      <Examples />
    </>
  );
};

export default Home;
