import React from 'react';
import styles from './styles.scss';

export interface Props {
  className?: string;
  headerText: string;
  subHeaderText: string;
}

const HeaderTitle = ({ className, headerText, subHeaderText }: Props): JSX.Element => {
  return (
    <div className={className}>
      <h1 className={styles.header}>{headerText}</h1>
      <h2 className={styles.subHeader}>{subHeaderText}</h2>
    </div>
  );
};

export default HeaderTitle;
