import classnames from 'classnames';
import React from 'react';
import SearchIcon from './search-icon.svg';
import styles from './styles.scss';

export interface Props {
  placeholderText?: string;
  containerStyles?: string;
  inputStyles?: string;
  lastSearch: string;
  onChange: (e?: React.ChangeEvent<HTMLInputElement>) => void;
}

// TODO: Add labelFor and id
const SearchBar = ({ containerStyles, inputStyles, onChange, placeholderText, lastSearch }: Props): JSX.Element => {
  return (
    <section className={classnames(styles.searchContainer, containerStyles && containerStyles)}>
      <input
        defaultValue={lastSearch}
        type="search"
        placeholder={placeholderText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChange(e)}
        className={classnames(styles.input, inputStyles && inputStyles)}
        autoComplete="off"
      />
      <label className={styles.searchIcon}>
        <SearchIcon />
      </label>
    </section>
  );
};

/* eslint-disable @typescript-eslint/no-empty-function */
const defaultProps: Props = {
  placeholderText: 'Søk',
  lastSearch: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
};

SearchBar.defaultProps = defaultProps;

export default SearchBar;
