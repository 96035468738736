import classnames from 'classnames';
import Link from 'next/link';
import React from 'react';
import Arrow from './arrow.svg';
import styles from './styles.scss';

interface Props {
  bilde: string;
  id: string;
  adresse: string;
  postnr: string;
  sted: string;
  className?: string;
}

const ExampleItem = ({ id, className, bilde, adresse, postnr, sted }: Props): JSX.Element => {
  return (
    <Link href={`/overview?tilstandsrapportId=${id}`} as={`/${id}`}>
      <a className={classnames(styles.container, className && className)}>
        <div className={styles.imageContainer}>
          <img src={bilde} alt="An example of a house" className={styles.image} />
        </div>
        <div className={styles.adressContainer}>
          <span className={styles.street}>{adresse}</span>
          <span>
            {postnr}, {sted}
          </span>
        </div>

        <Arrow className={styles.arrow} />
      </a>
    </Link>
  );
};

export default ExampleItem;
