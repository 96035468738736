import classnames from 'classnames';
import React from 'react';
import styles from './styles.scss';

type notificationType = 'info' | 'danger' | 'error';

interface Props {
  type: notificationType;
  text: string;
  className?: string;
}

const { container, info, danger, error } = styles;

const Notification = ({ type, text, className }: Props): JSX.Element => {
  const sectionName = classnames(
    container,
    { [`${info}`]: type === 'info' },
    { [`${danger}`]: type === 'danger' },
    { [`${error}`]: type === 'error' },
    className && className,
  );
  return (
    <section className={sectionName}>
      <p>{text}</p>
    </section>
  );
};

export default Notification;
