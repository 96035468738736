import axios from 'axios';
import getSBABaseUrl from 'lib/getSBABaseUrl';
import { formatPostnr, toTitleCase } from 'store/utils';
import { getJwtToken } from '../../lib/auth/helpers';
import { SearchActionCreators, SearchActionTypes } from './types';

export const searchClear = (): SearchActionCreators => {
  return { type: SearchActionTypes.CLEAR };
};

export const searchRequest = (address): SearchActionCreators => {
  return { type: SearchActionTypes.REQUEST, lastSearch: address };
};

const parseMatrikkelId = (matrikkelId: string): number[] => {
  const matrikkelElems = matrikkelId.split('-').map(elem => parseInt(elem, 10));
  return matrikkelElems.length === 6 ? matrikkelElems : null;
};
export const searchSuccess = (apiResults): SearchActionCreators => {
  const results = apiResults.map(tr => {
    const matrikkelElems = tr.matrikkelId ? parseMatrikkelId(tr.matrikkelId) : null;
    return {
      id: tr.id,
      knr: matrikkelElems ? matrikkelElems[0] : null,
      gnr: matrikkelElems ? matrikkelElems[1] : null,
      bnr: matrikkelElems ? matrikkelElems[2] : null,
      fnr: matrikkelElems ? matrikkelElems[3] : null,
      snr: matrikkelElems ? matrikkelElems[4] : null,
      anr: matrikkelElems ? matrikkelElems[5] : null,
      postnr: formatPostnr(tr.postnr),
      poststed: toTitleCase(tr.poststed),
      adresse: toTitleCase(tr.adresse),
    };
  });

  return { type: SearchActionTypes.SUCCESS, results };
};

export const searchFailure = (error): SearchActionCreators => {
  return { type: SearchActionTypes.FAILURE, error };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const doSearch = (address: string) => {
  return async (dispatch): Promise<void> => {
    if (!address) {
      dispatch(searchClear());
    } else {
      dispatch(searchRequest(address));
      try {
        const token = await getJwtToken();
        const { data } = await axios.get(`${getSBABaseUrl()}/api/search?address=${address}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch(searchSuccess(data));
      } catch (err) {
        dispatch(searchFailure(err));
      }
    }
  };
};
