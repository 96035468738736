import classnames from 'classnames';
import Notification from 'components/common/Notification';
import debounce from 'lodash.debounce';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { doSearch } from 'store/search/actions';
import { SearchState } from 'store/search/types';
import SearchBar from './SearchBar';
import SearchResult from './SearchResult';
import styles from './styles.scss';

interface Props {
  containerClassName?: string;
  resultContainerClassName?: string;
  notificationsClassName?: string;
}

const Search = ({ containerClassName, resultContainerClassName, notificationsClassName }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { results, pending, error, lastSearch } = useSelector<AppState, SearchState>(state => state.search);
  const [hasSearched, setHasSearched] = React.useState(false);

  const searchDebounced = debounce(address => {
    setHasSearched(address.length > 0);
    dispatch(doSearch(address));
  }, 200);

  return (
    <section className={containerClassName && containerClassName}>
      <SearchBar lastSearch={lastSearch} onChange={(e): void => searchDebounced(e.target.value)} />

      {!pending && results.length > 0 && (
        <div
          data-testid="search-results"
          className={classnames(styles.searchResults, resultContainerClassName && resultContainerClassName)}
        >
          {results.map(({ id, snr, anr, postnr, poststed, adresse }) => (
            <SearchResult key={id} id={id} snr={snr} anr={anr} postnr={postnr} poststed={poststed} adresse={adresse} />
          ))}
        </div>
      )}
      {!pending && hasSearched && !error && results.length === 0 && (
        <Notification
          className={classnames(styles.notifications, notificationsClassName)}
          type="danger"
          text="Vi kan ikke finne en bolig med den adressen. Sjekk med megler om det foreligger en tilstandsrapport."
        />
      )}
      {!pending && hasSearched && error && results.length === 0 && (
        <Notification
          className={classnames(styles.notifications, notificationsClassName)}
          type="error"
          text="Det skjedde en uforventet feil ved søk. Prøv igjen..."
        />
      )}
    </section>
  );
};

export default Search;
