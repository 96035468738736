import ExampleItem from 'components/common/ExampleItem';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store';
import { doFetchExamples } from 'store/example/actions';
import { Example } from 'store/example/types';
import styles from './styles.scss';

interface StateProps {
  examples: Example[];
}

interface DispatchProps {
  doFetchExamples: Function;
}

const Examples = (props: StateProps & DispatchProps): JSX.Element => {
  useEffect(() => {
    // Note: Examples rendered client side. Fix if this is an issue.
    props.doFetchExamples();
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <h2 className={styles.header}>Eksempel</h2>
        <ul className={styles.examples}>
          {props.examples.map(({ id, bilde, adresse, postnr, poststed }) => (
            <li key={id}>
              <ExampleItem bilde={bilde} id={id} adresse={adresse} postnr={postnr} sted={poststed} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  examples: state.examples.data,
});

export default connect(mapStateToProps, { doFetchExamples })(Examples);
